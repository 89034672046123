.otp-container {
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  gap: 8px;
}

.otp-input {
  width: 32px !important;
  border-width: 0;
  border-bottom: 2px solid #D9DBE9;
  outline: none;
  margin-right: 5px;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #262338;
  padding: 0;
}

.otp-input .otp-focus {
  border-bottom: 2px solid #06B6AA;
}

.otp-input .otp-error {
  border-bottom: none;
  outline: auto;
  outline-width: 2px;
  outline-color: #E81C3A;
}
.pincode-input-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.pincode-input-container .pincode-input-text {
  padding: 0 !important;
  text-align:center;
  border-width: 0 !important;
  border-bottom: 2px solid #D9DBE9 !important;
  outline: none !important;
  width: 48px !important;
  background: transparent;
  font-size: 18px;
  margin: 0 2px;
  color: #262338;
}

.pincode-input-container .pincode-input-text:focus {
  outline: none;
  box-shadow: none;
}

.pincode-input-container input:not([type-range]) {
  touch-action: none !important;
}

.ant-btn-circle {
  width: auto !important;
  color: #06B6AA !important;
}
.ant-notification {
  width: 100%;
  display: grid;
  place-items: center;
  right: auto !important;
}

.ant-notification-notice {
  border-radius: 8px !important;
}

.success .ant-notification-notice-message, .success .ant-notification-notice-icon, .success .ant-notification svg, .success .anticon-close {
  color: #007358 !important;
}

.error .ant-notification-notice-message, .error .ant-notification-notice-icon, .error .ant-notification svg, .error .anticon-close {
  color: #E81C3A !important;
}

.ant-notification-notice-message {
  margin-left: 36px !important;
}

.ant-notification-notice-icon {
  margin-top: -2px !important;
}

.ant-notification-notice-close {
  margin-top: -3px;
}

.ant-notification svg {
  height: 16px !important;
}
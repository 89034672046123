* {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

body {
  background: #f5f5f5 !important;
}

.terms-and-conditions-title {
  padding-right: 24px;
}